<template>
  <div>
    <div class="content">
      <a-alert
        message="Heat Analysis allows you to query the frequency of keyword occurrence in Pubmed articles."
        class="scholar-tips"
        closable
      />
      <div class="main">
        <div class="origin">
          <div class="desc">
            <p class="color-primary">Source</p>
            <p>
              {{ originInputValueList.length }} items
              <span class="collapse" @click="handleOriginSwitch">
                <a-icon type="plus" v-if="originSwitch" />
                <a-icon type="minus" v-else />
              </span>
            </p>
          </div>
          <template v-if="originSwitch">
            <p
              class="origin-value"
              v-for="(item, index) in originInputValueList"
              :key="index + 'origin'"
            >
              <span>{{ item }}</span>
              <span @click="cancelOriginValue(index)" class="cancel">X</span>
            </p>
          </template>
          <div class="origin-input">
            <a-input
              placeholder="Please enter a source value"
              allowClear
              ref="sourceInput"
              :class="['input', borderRed === 'source' ? 'has-error' : '']"
              v-decorator="[
                'note',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' },
                  ],
                },
              ]"
              v-model="originInputValue"
              @pressEnter="handleAddOrigin"
            />
            <a-button type="default" @click="handleAddOrigin" class="add-btn"
              >ADD</a-button
            >
          </div>
          <div class="desc">
            <p class="color-primary">Target</p>
            <p>
              {{ endInputValueList.length }} items
              <span class="collapse" @click="handleEndSwitch">
                <a-icon type="plus" v-if="endSwitch" />
                <a-icon type="minus" v-else />
              </span>
            </p>
          </div>
          <template v-if="endSwitch">
            <p
              class="origin-value"
              v-for="(item, index) in endInputValueList"
              :key="index + 'end'"
            >
              <span>{{ item }}</span>
              <span class="cancel" @click="cancelEndValue(index, $event)"
                >X</span
              >
            </p>
          </template>
          <div class="origin-input">
            <a-input
              placeholder="Please enter a target value"
              ref="targetInput"
              allowClear
              :class="['input', borderRed === 'target' ? 'has-error' : '']"
              v-model="endInputValue"
              @pressEnter="handleAddEnd"
            />
            <a-button type="default" @click="handleAddEnd" class="add-btn"
              >ADD</a-button
            >
          </div>
          <a-button type="primary" @click="searchKeyword" class="btn-search"
            >Search</a-button
          >
        </div>
        <div class="main-table">
          <a-table
            :columns="columns"
            :dataSource="data"
            bordered
            :rowKey="(record) => record.uid"
          >
            <template slot="action" slot-scope="text, record">
              <a @click="handleViewArticle(text, record)" class="view-article"
                >View Article</a
              >
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { users } from "@/api/modules/login"

export default {
  name: "heat-analysis",
  data() {
    return {
      columns: [
        { title: "Source", dataIndex: "start" },
        { title: "Target", dataIndex: "end" },
        {
          title: "Articles",
          dataIndex: "number",
          sorter: (a, b) => a.number - b.number,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "action" },
        },
      ],
      data: [],
      originInputValue: "",
      endInputValue: "",
      originInputValueList: [],
      endInputValueList: [],
      originSwitch: true,
      endSwitch: true,
      resultInfo: [],
      borderRed: "",
    }
  },
  mounted() {
    users()
      .then((res) => {
        this.$store.commit("analysis/setAuthenticated", true)
      })
      .catch((error) => {
        if (error.code === 401) {
          this.$store.commit("analysis/setAuthenticated", false)
          this.$router.push({ name: "Login" })
        }
      })
      .finally(() => {
        this.$store.commit("analysis/setLoading", false)
      })
  },
  methods: {
    handleViewArticle(text, record) {
      window.open(record.link, "_blank")
    },
    handleAddOrigin() {
      const value = this.originInputValue
      if (value === "" || this.originInputValueList.includes(value)) {
        return
      } else {
        this.originInputValueList.push(value)
      }
      this.originInputValue = ""
      this.borderRed = ""
    },
    handleAddEnd() {
      const value = this.endInputValue
      if (value === "" || this.endInputValueList.includes(value)) {
        return
      } else {
        this.endInputValueList.push(value)
      }
      this.endInputValue = ""
      this.borderRed = ""
    },
    cancelOriginValue(index) {
      this.originInputValueList.splice(index, 1)
    },
    cancelEndValue(index, event) {
      this.endInputValueList.splice(index, 1)
    },
    handleOriginSwitch() {
      this.originSwitch = !this.originSwitch
    },
    handleEndSwitch() {
      this.endSwitch = !this.endSwitch
    },
    searchKeyword() {
      if (this.originInputValueList.length <= 0) {
        this.$refs.sourceInput.focus()
        this.borderRed = "source"
      } else if (this.endInputValueList.length <= 0) {
        this.$refs.targetInput.focus()
        this.borderRed = "target"
      }
      const dbUrl =
        "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed"
      this.formateData()
      this.data.forEach((item) => {
        axios
          .get(`${dbUrl}&term=${item.start},${item.end}&retmode=json`)
          .then((res) => {
            item.number = res.data.esearchresult.count
            item.link = `https://www.ncbi.nlm.nih.gov/pmc/?term=${item.start},${item.end}`
          })
      })
    },
    formateData() {
      let origin = this.originInputValueList
      let end = this.endInputValueList
      let formateArray = []
      origin.forEach((item) => {
        end.forEach((ele) => {
          formateArray.push({
            start: item,
            end: ele,
            number: "",
            url: "",
          })
        })
      })
      this.data = formateArray
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ has-error.ant-input:focus {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}

.content {
  .scholar-tips {
    font-size: 12px;
  }
  .main {
    margin-top: 2em;
    @include flex-contianer(row);
    .origin {
      width: 300px;
      margin-right: 24px;
      .desc {
        font-weight: 500;
        margin-bottom: 1em;
        @include flex-contianer(row, center, space-between);
        .collapse {
          font-size: 12px;
          border-radius: 4px;
          padding: 4px;
          border: 1px solid #2db1b0;
          cursor: pointer;
          margin-left: 1em;
        }
      }
      .origin-input {
        @include flex-contianer(row, center, space-between);
        margin-bottom: 2em;
        .input {
          margin-right: 10px;
        }
      }
      .origin-value {
        border: 1px solid #2db1b0;
        padding: 6px 10px;
        border-radius: 3px;
        margin-bottom: 0.5em;
        @include flex-contianer(row, center, space-between);
        .cancel {
          cursor: pointer;
          color: #2db1b0;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .btn-search {
        width: 100%;
        font-size: 16px;
        background-color: #2db1b0;
        border: 1px solid #2db1b0;
      }
    }
    .main-table {
      flex: 1;
      .view-article {
        padding: 0;
        color: #2db1b0;
      }
    }
    .add-btn {
      background-color: #fff;
      color: #2db1b0;
    }
  }
}
</style>
